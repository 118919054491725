import React from 'react'

const About= () => {
  return(
    <div>
      <div className = "container">
        <div id = "blurb">
          <h1>Hello!</h1>
          <h3 id = "blurb1">My name is Emmett Smith and I love creating and playing games! I grew up in Maine and love being outdoors, whether it's on the water sailing or on the mountains skiing and hiking! I'm competitive and outspoken, and enjoy working with others. I look forward to working with your team!</h3>
        </div>
        <div>
          <img id = "profilePic" src = {require("../images/profile_1.jpg")} alt = "Emmett Smith"/>
        </div>
      </div>
      <div className = "container">
        <div className = "container">
          <div>
            <h3 className = "list">Languages</h3>
            <ul>
              <li>C#</li>
              <li>C++</li>
              <li>Javascript</li>
            </ul>
          </div>
          <div>
            <h3 className = "list">Programs</h3>
            <ul>
              <li>Unity</li>
              <li>Unreal</li>
              <li>Microsoft Office</li>
              <li>Adobe Suite</li>
            </ul>
          </div>
        </div>
        <div>
          <h3 id= "blurb2"> I have been working with Unity for over five years in both 2D and 3D with a quick foray into VR. I have been elected the programming lead by my peers for multiple game studio's and am comfortable working with others.</h3>
        </div>
      </div>
      <img id = "landscape" src = {require("../images/HikingPhoto.jfif")} alt = "HikingPhoto"/>
    </div>
  )
}

export default About;
