import React from 'react'

const Sudoku = () => {
  return(
      <div>
        <h1> Sudoku</h1>

        <h3>This is a C++ program which uses a wave function collapse algorithm to generate solvable Sudoku puzzles. The player then can then try their hand at solving the puzzle. This program demonstrates my knowledge of OOP, as well as core programming concepts like encapsulation, inhertiance and abstraction. The fourth pillar of polymorphism was not necessary for this particular project, but you can see it at work in the enemy AI from Prisoner's Dilemma. </h3>
        <a className = "githubLink" href="https://github.com/Ba1thazaar/Sudoku.git" target = "_blank" rel = "noopener noreferrer">Project Link</a>
        <div class="container">
          <div>
            <img class = "containerImage" src = {require("../images/MainGameplayLoop.png")} alt = "image of code"/>
            <h3>The code displayed above simply is the main application code, which enforces the main gameplay loop. It uses a constructor to generate the board, and then calls the MakeGuess function on the generated board until they make 10 incorrect guesses, at which point you lose. The board returns a bool through board.GuessValue which determines if they got it right.</h3>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/MainGamePlayLoopImage.png")} alt = "image of a console"/>
            <h3>This is what the console shows the player as they play the game, asking for coordinates and values for their guesses. </h3>
          </div>
        </div>
        <div class="container">
          <div>
            <img class = "containerImage" src = {require("../images/SolutionGenerationSudoku.PNG")} alt = "image of code"/>
            <h3>This is the meat and potatoes of the application, the actual solution generation which takes place inside the board class. This class represents a 2D array of Node objects. The program will generate random values for the nodes with the lowest entropy. Each node represents a coordinate location, and it's entropy changes based on how many legal possibilities it has based on the rules of Sudoku. These are the superpositions which the node holds.</h3>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/SudokuNode.PNG")} alt = "image of code"/>
            <h3>The node class is fairly straight forward, and mainly just records its position, superpositions, and it's determined value once it's calculated. There are also several functions which allow for the access or change of these values.</h3>
          </div>
        </div>
        <div class="container">
          <div>
            <img class = "containerImage" src = {require("../images/CollapseAndRemoval.png")} alt = "image of code"/>
            <h3>The Collapse function is used to remove superpositons from other nodes once a value has been confirmed for one of the original nodes. The HideValues function just hides a random assortment of values from the solved board, this is what the player has to solve.</h3>
          </div>
          <div>
            <h3 className = "endingBlurb">I faced a few challenges while creating this program, but I had a lot of fun making it! I learned about the concept of a wave function collapse and coming up with my own simple implementation successfully was very satisfying.</h3>
          </div>
        </div>
      </div>
  )
}

export default Sudoku
