import React from 'react'

import {
  BrowserRouter as Router,
  Switch, Route, Link
} from "react-router-dom"

//Publish from Netlify

import Gallery from './pages/Gallery'
import About from './pages/About'
import Eidolon6 from './pages/Eidolon6'
import PrisonersDilemma from './pages/PrisonersDilemma'
import Sudoku from './pages/Sudoku'

const App = () => {
  return(
    <div id = "homepage">
      <Router>
        <header>
          <h1 id = "name">Emmett Smith</h1>
          <div className = "headLinks">
            <Link to = "/about" className = "link">
              <h3>About</h3>
            </Link>
            <Link to = "/" className = "link">
              <h3>Gallery</h3>
            </Link>
          </div>
        </header>
        <main>
          <Switch>
            <Route path = "/About">
              <About/>
            </Route>
            <Route path = "/Eidolon6">
              <Eidolon6/>
            </Route>
            <Route path = "/PrisonersDilemma">
              <PrisonersDilemma/>
            </Route>
            <Route path = "/Sudoku">
              <Sudoku/>
            </Route>
            <Route path = "/">
              <Gallery/>
            </Route>
          </Switch>
        </main>
        <footer>
          <h5>Email: smithwe888@gmail.com</h5>
          <h5>Phone: 207-208-7766</h5>
          <h5>LinkedIn: www.linkedin.com/in/emmett-smith-810846163</h5>
          <h5>Discord: M_1t#3853</h5>
        </footer>
      </Router>
    </div>
  )
}


export default App
