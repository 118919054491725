import React from 'react'
import ImageGallery from 'react-image-gallery';
import Carousel from 'react-bootstrap/Carousel'


import {
  Link
} from "react-router-dom"

const Gallery = () => {
  const parameters = {
    showNav: true,
    showThumbnails: false,
    showFullscreenButton: false,
    showPlayButton: false,
    autoPlay: true,
    slideInterval: 6000

  }
  const images = [
    'https://drive.google.com/uc?export=view&id=1B6fsbNmi6-DaF_kX2QEI4u_5ngo7SkBr',
    "https://drive.google.com/uc?export=view&id=184myaauZ3Lzj3ClNlsZ4ixNPbbKuoEWQ",
    'https://drive.google.com/uc?export=view&id=1SfO7FhsUqc8g4p1dCmNbpLuvIYGh-y6R',
    'https://drive.google.com/uc?export=view&id=1rkDpmk01Lk4qpSvkUb80cP3ellDVegbq',
    'https://drive.google.com/uc?export=view&id=1fkU5aBL6z56bm210Mcewn7BM3bqqFgOR',
    'https://drive.google.com/uc?export=view&id=1sVEHI8irhsVzl8YkvmP_CNJX_RURYiOI',
  ]
  return (
    <div>
    <Carousel>
    {images.map((url) => (
        <Carousel.Item>
          <img src={url} alt="Image" className = "bannerImages"/>
        </Carousel.Item>
      ))}
    </Carousel>
      <div className = "galleryProject">
        <h1>Prisoners Dilemma</h1>
        <Link to = "/PrisonersDilemma" >
          <img src = {require("../images/PDLinkPic.png")} className = "imageLink"/>
        </Link>
        <h3>This game is a 3rd person procedurally generated rougelite and features combat inspired by the Souls series.</h3>
      </div>
      <div className = "galleryProject">
        <h1>Sudoku Project</h1>
        <Link to = "/Sudoku">
          <img src = {require("../images/SudokuLinkPic.png")} className = "imageLink"/>
        </Link>
        <h3>This is a simple C++ project which uses a wave function collapse to generate sudoku puzzles for players to solve.</h3>
      </div>
      <div className = "galleryProject">
        <h1>Eidolon6</h1>
        <Link to = "/Eidolon6">
          <img src = {require("../images/E6LinkPic.png")} className = "imageLink"/>
        </Link>
        <h3>This is a simple walking simulator puzzle game which takes place in a futuristic abandoned city inspired by the visuals from TRON.</h3>
      </div>
      <div className = "galleryProject">
        <h1>Untitled Fishing Game</h1>
        <img src = {require("../images/UTFishingLinkPic.png")} />
        <h3>CURRENTLY UNDER CONSTRUCTION</h3>
      </div>
    </div>
  )
}
export default Gallery
