import React from 'react'
import Mechanic from '../components/mechanic'


const Eidolon6 = () => {
  return(
      <div>
        <h1> Eidolon6: </h1>
        <h3>Eidolon6 is a 3D puzzle platformer where you make your way through abandoned futuristic skyscrapers to try and find your way out of the smog to the stars.</h3>
        <a className = "githubLink" href="https://github.com/Ba1thazaar/Eidolon6.git" target = "_blank" rel = "noopener noreferrer">Project Link</a>
        <div class = "container">
          <div>
            <img class = "containerImage" src = {require("../images/puzzle1_code.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/puzzle1_code.gif")} alt = "image of code"/>
            <h3>This is one of the puzzles featured in the game, it's fairly straightforward. Each button raises two platforms and you have to form them all into a ramp in order to reach the power orb at the top.</h3>
          </div>
        </div>
        <div class = "container">
          <div>
            <img class = "containerImage" src = {require("../images/cloud_shader.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/cloud_gif.gif")} alt = "image of code"/>
            <h3>I learned a lot about creating shaders on this project, and the cloud that forms the base of the area where most of the puzzles are was created with unity shadergraph.</h3>
          </div>
        </div>
        <div class = "container">
          <div>
            <img class = "containerImage" src = {require("../images/OrbStationCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/OrbStationImage.PNG")} alt = "image of code"/>
            <h3>These are stations which the player has to power with orbs in order to progress through the level. The orb objects can be carried around and transferred between stations.</h3>
          </div>
        </div>
        <div class = "container">
          <div>
            <img class = "containerImage" src = {require("../images/OrbCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/OrbAction.gif")} alt = "image of code"/>
            <h3>This is some of the code which controls the orb itself, you can drop the orb on the ground or deposit it inside a station.</h3>
          </div>
        </div>
      </div>
  )
}

export default Eidolon6
