import React from 'react'
import Mechanic from '../components/mechanic'

//Publish from Netlify

const PrisonersDilemma = () => {
  return(
      <div>
        <h1> Prisoners Dilemma </h1>
        <h3>Prisoners Dilemma is a top down dungeon crawler that uses procedurally generated maps. It also has intelligent enemies that use a Finite State Machine model to give the player an interactive experience.</h3>
        <a className = "githubLink" href="https://github.com/Ba1thazaar/PrisonersDilemma" target = "_blank" rel = "noopener noreferrer">Project Link</a>
        <div className = "container">
          <div>
            <img class = "containerImage" src = {require("../images/wallcount_code.PNG")} alt = "image of code"/>
            <h3>The map is generated from random noise, and then curated to form a cavelike structure. It is then populated with objectives, decorations, and enemies. The above function is one of the many functions extremely useful in determining factors which decide how things are placed.</h3>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/MapGeneration.PNG")} alt = "image of code"/>
            <h3>After the map is decided, a mesh is generated using the marching squares algorithm, which creates the 3D environment which the player moves through.</h3>
          </div>
        </div>
        <div className = "container">
          <div>
            <img class = "containerImage" src = {require("../images/FSMCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/EnemyDemo.gif")} alt = "image of code"/>
            <h3>There are responsive enemies which use a Finite State Machine to operate, they have an idle, attack, follow and stunned state. The player can take them down using a variety of weapons.</h3>
          </div>
        </div>
        <div className = "container">
          <div>
            <img class = "containerImage" src = {require("../images/AStarCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/EnemyFollow.gif")} alt = "image of code"/>
            <h3>The enemies move throughout the generated terrain using the A* algorithm. The A* algorithm calculates pathing based on the generated data of the map.</h3>
          </div>
        </div>
        <div className = "container">
          <div>
            <img class = "containerImage" src = {require("../images/WeaponPickupCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/WeaponPickup.PNG")} alt = "image of code"/>
            <h3>The player can purchase or find weapons hidden throughout the dungeon. Each weapon has its own feel to it and some are more suited to fighting different enemies. The player can actively hold two weapons at once and switch between them freely.</h3>
          </div>
        </div>
        <div className = "container">
          <div>
            <img class = "containerImage" src = {require("../images/DragonAttackCode.PNG")} alt = "image of code"/>
          </div>
          <div>
            <img class = "containerImage" src = {require("../images/DragonBoss.gif")} alt = "image of code"/>
            <h3>The boss will perform different attacks based on the distance to the player. These attacks are objects based on their own class and can be modified and created conveniently.</h3>
          </div>
        </div>
      </div>
  )
}

export default PrisonersDilemma
